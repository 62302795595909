<template>
<div class="content-box">
        <div class="container">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>协同管理</el-breadcrumb-item>
      <el-breadcrumb-item>协同制造</el-breadcrumb-item>
      <el-breadcrumb-item>制造需求发布</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="createButton">
      <el-button
        style="margin-right: 12px"
        type="primary"
        @click="createNewDemand"
        v-show="userType=='超级管理员'||userType=='海装风电'"
        >发布制造需求</el-button
      >
    </div>

    <div class="content_table">
      <el-table :data="tableData" border max-height="500">
        <el-table-column align="center" width="50" label="序号">
          <template slot-scope="scope">
            <span>{{ scope.$index + 1 }}</span>
          </template>
        </el-table-column>
            <el-table-column   v-if="this.userType=='海装风电'||userType=='超级管理员'" prop="userInfo.company" align="center" label="接受公司">
        </el-table-column>
           <el-table-column   v-if="this.userType=='供应商'" prop="userInfo.company" align="center" label="发布公司">
        </el-table-column>

               <el-table-column  v-if="this.userType=='海装风电'||userType=='超级管理员'"  align="center" label="接受人">
                <template  slot-scope="scope">
                  <span v-if="scope.row.manufacturingRelease.stateMessage==0">暂无</span>
                   <span v-else-if="scope.row.manufacturingRelease.stateMessage==1">{{scope.row.userInfo.name}}</span>
                </template>
                </el-table-column>
               <el-table-column prop="userInfo.name" v-if="this.userType=='供应商'"  align="center" label="发布人">
        </el-table-column>
        <el-table-column prop="manufacturingRelease.name" align="center" label="需求名称">
        </el-table-column>
        <el-table-column prop="manufacturingRelease.content" align="center" label="需求内容">
        </el-table-column>
        <el-table-column prop="manufacturingRelease.needCount" align="center" label="需求数量">
        </el-table-column>
        <el-table-column prop="manufacturingRelease.timeCreated" align="center" label="任务创建时间">
        </el-table-column>
        <el-table-column prop="manufacturingRelease.timeAbort" align="center" label="截止时间">
        </el-table-column>
        <el-table-column align="center" width="200" label="操作">
          <template slot-scope="scope">
            <el-popconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              confirm-button-type="danger"
              icon="el-icon-info"
              icon-color="green"
              title="确定接受吗？"
              @confirm="userReslove(scope.row, scope.$index)"
            >
              <el-button
                size="mini"
                type="success"
                slot="reference"
                v-if="scope.row.manufacturingRelease.stateMessage==0"
                v-show="userType == '供应商'"
                >接受</el-button
              >
            </el-popconfirm>
                <el-button
                size="mini"
                type="success"
                slot="reference"
                disabled
                v-if="scope.row.manufacturingRelease.stateMessage==1"
               
                >已接受</el-button
              >
            <el-popconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              confirm-button-type="danger"
              icon="el-icon-info"
              icon-color="red"
              title="确定删除吗？"
              @confirm="deleteFile(scope.row, scope.$index)"
            >
              <el-button
                size="mini"
                type="danger"
                slot="reference"
                v-if="scope.row.manufacturingRelease.stateMessage==0"
                v-show="userType == '超级管理员' || userType == '海装风电'"
                >删除</el-button
              >
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>
      <el-dialog
      title="发布制造需求"
      :visible.sync="ceateDialogVisible"
      width="500px"
      :before-close="Cancel"
    >
      <el-form
        style="width: 400px"
        ref="manufacturFrom"
        :model="manufacturFrom"
        label-position="left"
        label-width="120px"
      >
        <el-form-item label="需求名称" required>
          <el-input
            v-model="manufacturFrom.name"
            placeholder="请输入需求名称"
            clearable
            :style="{ width: '100%' }"
          ></el-input>
        </el-form-item>
        <el-form-item label="需求内容" required>
          <el-input
            v-model="manufacturFrom.content"
            placeholder="请输入需求内容"
            clearable
            :style="{ width: '100%' }"
          ></el-input>
        </el-form-item>
        <el-form-item label="需求数量" required :style="{ width: '100%' }">
          <el-input-number
            :style="{ width: '100%' }"
            v-model="manufacturFrom.needCount"
            placeholder="需求数量"
            :step="10"
          ></el-input-number>
        </el-form-item>
        <!-- <el-form-item label="所属合同" :style="{ width: '100%' }" required>
          <el-select
            :style="{ width: '100%' }"
            v-model="manufacturFrom.contractId"
            placeholder="请选择"
          >
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.lable"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="截止时间" required>
          <el-date-picker
            v-model="manufacturFrom.timeAbort"
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd HH:mm"
            :style="{ width: '100%' }"
            placeholder="请选择截止时间"
            clearable
          ></el-date-picker>
        </el-form-item>
        <el-form-item> </el-form-item>
      </el-form>
      <div slot="footer" class="footer">
        <el-button @click="Cancel">取 消</el-button>
        <el-button type="primary" @click="Send(manufacturFrom)"
          >确 定</el-button
        >
      </div>
    </el-dialog>
 


    <el-dialog
      title="提示:请选择供应商"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="Cancel"
    >
      <el-form
        :model="pushFrom"
        status-icon
        :rules="otherRule"
        ref="pushFrom"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="供应商：" prop="userId">
          <el-select
            v-model="pushFrom.userId"
            placeholder="请选择供应商："
            clearable
            :style="{ width: '100%' }"
          >
            <el-option
              v-for="(item, index) in publishOptions"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <div class="btn_item">
            <el-button @click="Cancel('pushFrom')">取消</el-button>
            <el-button type="primary" @click="SendData('pushFrom')"
              >确定</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>

 
    <el_pagination
      :total="total"
      :num="num"
      @page-limit="page_limit"
    ></el_pagination>

</div>
</div>
</template>

<script>
import {

  needList,
  deleteNeedList,
  pushManuFacturing,
  contractlist,
  acceptOrders,
  addmanuFacturing,
  pushCreate,
  gsyNeedList,

} from "@/api/document";
import { getUserList } from "@/api/user";

// import DemandPushDialog from "@/views/collaborative/manufacture/DemandPushDialog";

export default {
  name: "DemandPush",
  components: {},
  data() {
    return {
      manufacturFrom: {
      
      state:'0',
      userId:  localStorage.getItem('userId'),


      },
      ceateDialogVisible: false,
      dialogVisible: false,
      tableData: [],
      pushFrom: {},
      publishOptions: [],
      mode: "add",
      data: {},
      userType: localStorage.getItem('userType'),
      pageSize: 10,
      currentPage: 1,
      total: 0,
      num: 0,
      otherRule: {
        userId: [
          { required: true, message: "至少选择一个供应商", trigger: "blur" },
        ],
      },
      statusOptions: [],
    };
  },
  created() {
    this.getFileList();
  },
  mounted() {
    this.getUserType();
    this.getcontractList();
  },
  methods: {
    // 分页子集传过来的数据  page = 页码  limit = 条数
    // 分页子集传过来的数据  page = 页码  limit = 条数
    page_limit(list) {
      console.log(list);
      let { page, limit } = list;
      this.currentPage = page;
      this.pageSize = limit;
      this.tableData = [];
      this.getFileList();
    },

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    async getFileList() {
      let limit = this.pageSize;
      let page = this.currentPage;
      const res = await needList({
        limit,
        page,
      });

      if (res.code === 200) {
        this.tableData = res.data;
        this.total = res.total;
        this.num = parseInt(this.total / limit + 1);
      }
    },
    async deleteFile(row) {
      let id = row.manufacturingRelease.id;
      const res = await deleteNeedList({
        id,
      });
      if (res.code === 200) {
        this.$message.success("删除成功");
      } else {
        this.$message.error("删除失败");
      }
      await this.getFileList();
    },
    async getUserType(item) {
      let res = await getUserList();

      if (res.code == 200) {
        console.log(res.data);
        let userList = [];
        res.data.forEach((item) => {
          if (item.name !== this.userName && item.resume == 3) {
            let userData = {};
            //console.log(item)
            userData.value = item.id;
            userData.label = item.username;
            userList.push(userData);
          }
        });
        console.log(userList);
        this.publishOptions = userList;
      }
    },

    // 获取合同

    async getcontractList() {
      let res = await contractlist();
      let optionData = {};
      //  let optionList  = []
      if (res.code == 200) {
        res.data.forEach((item) => {
          optionData.lable = item.contractNo;
          optionData.value = item.id;
          this.statusOptions.push(optionData);
          optionData = {};
        });
        // this.statusOptions=optionList
        // console.log(this.statusOptions)
      }
    },

    //推送弹框

   async Send(manufacturFrom) {
           console.log(manufacturFrom);
            
      const res = await addmanuFacturing(manufacturFrom);
      if (res.code == 200) {
        this.$message.success("发布成功");
        this.ceateDialogVisible =false
        this.manufacturFrom ={}
         await  this.getFileList()
      }else{
        this.$message.error("发布失败");
             this.ceateDialogVisible =false
        this.manufacturFrom ={};
      }
    },


    //新增制造需求
    createNewDemand() {
      this.ceateDialogVisible = true;
    },

    // 创建制造需求
    async userReslove(row,$index) {
        let id  = row.manufacturingRelease.id
      
      let res = await acceptOrders({
        id,
      });
   
      if (res.code == 200) {
        this.$message.success("你已接受此订单");
        this.getFileList();
      } else {
        this.$message.error("网络错误请检查网络");
   
      
      }
    },
    //取消推送
    Cancel() {
      this.ceateDialogVisible = false;
      this.dialogVisible = false;
      this.pushFrom = {};
    },
  },
};
</script>

<style lang="scss" scoped>
.button-gutter {
  margin: 20px 0;
}
.btn_item {
  margin-top: 50px;
  width: 100%;

  text-align: center;
}
.createButton {
  margin-top: 20px;
}
.content_table {
  margin-top: 20px;
}
::v-deep .el-dialog__header {
  text-align: center;
}
.footer {
  width: 300px;
  text-align: center;
  margin: 0 auto;
}
</style>

